import production from './production';
import development from './dev';

const envs: any = {
    development,
    production
}

let _env: any = development;
const env = (config: string) => {
    _env = { ...envs[config] }
}

env(`${process.env.NODE_ENV}` || 'production');
_env = production;

export default _env