import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "grid px-[60px] py-[24px] border-b-[1px]",
  style: {"grid-template-columns":"1fr 2fr"}
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "flex items-center gap-[6px] border-[1px] rounded-[100px] w-fit px-[20px] py-[3px]" }
const _hoisted_4 = {
  class: "grid items-center gap-[10px]",
  style: {"grid-template-columns":"auto 2fr auto"}
}
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "flex justify-between gap-[30px] cursor-pointer px-[60px] pt-[60px]" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text-center text-caption mt-[10px]" }
const _hoisted_10 = { class: "pl-[60px] pt-[60px]" }
const _hoisted_11 = { class: "flex justify-between my-[37px] items-center pr-[60px]" }
const _hoisted_12 = { class: "flex items-center gap-[10px]" }
const _hoisted_13 = { class: "w-[56px] h-[56px] rounded-full bg-bg-200 text-grey-300 relative" }
const _hoisted_14 = { class: "w-[56px] h-[56px] rounded-full bg-bg-200 text-grey-300 relative" }
const _hoisted_15 = { class: "flex gap-[26px] overflow-auto no-scrollbar" }
const _hoisted_16 = { class: "font-semibold mt-[12px]" }
const _hoisted_17 = { class: "my-[12px] text-caption text-grey-200" }
const _hoisted_18 = { class: "flex items-center gap-[10px]" }
const _hoisted_19 = { class: "text-caption text-grey-200" }
const _hoisted_20 = { class: "px-[60px]" }
const _hoisted_21 = { class: "grid grid-cols-5 gap-[26px] overflow-auto no-scrollbar" }
const _hoisted_22 = { class: "font-semibold mt-[12px]" }
const _hoisted_23 = { class: "my-[12px] text-caption text-grey-200" }
const _hoisted_24 = { class: "flex items-center gap-[10px]" }
const _hoisted_25 = { class: "text-caption text-grey-200" }
const _hoisted_26 = { class: "mt-[60px] border-t-[1px]" }
const _hoisted_27 = { class: "grid bg-[#fff] grid-cols-2 px-[60px] py-[60px]" }
const _hoisted_28 = { class: "flex items-center gap-[6px] border-[1px] w-fit py-[8px] px-[20px] rounded-[50px] h-fit" }
const _hoisted_29 = { class: "bg-[#fff] text-grey-900 px-[60px] py-[34px] flex items-center justify-between text-caption border-t-[1px]" }
const _hoisted_30 = { class: "text-white flex gap-[16px]" }
const _hoisted_31 = { class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative" }
const _hoisted_32 = { class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative" }
const _hoisted_33 = { class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RestaurantsView',
  setup(__props) {

const restaurants = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'La Spag - Ibadan',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        reviews: 126
    },
]);

const items = ref([
    { 
        icon: require('@/assets/images/icons/table.png'), 
        label: 'All' 
    },

    { 
        icon: require('@/assets/images/icons/chicken.png'), 
        label: 'Chicken' 
    }, 

    { 
        icon: require('@/assets/images/icons/Cooked.png'), 
        label: 'Rice' 
    }, 

    { 
        icon: require('@/assets/images/icons/Cupcake.png'), 
        label: 'Cup Cake' 
    }, 

    { 
        icon: require('@/assets/images/icons/HotSoup.png'), 
        label: 'Soup' 
    }, 

    { 
        icon: require('@/assets/images/icons/JuiceGlass.png'), 
        label: 'Parfait' 
    }, 

    { 
        icon: require('@/assets/images/icons/Cabbage.png'), 
        label: 'Salad' 
    }, 

    { 
        icon: require('@/assets/images/icons/Spaghetti.png'), 
        label: 'Pasta' 
    }, 

    { 
        icon: require('@/assets/images/icons/Porridge.png'), 
        label: 'Porridge' 
    }, 

    { 
        icon: require('@/assets/images/icons/lemonjuice.png'), 
        label: 'Juice' 
    }, 

    { 
        icon: require('@/assets/images/icons/Smoothie.png'), 
        label: 'Smoothie' 
    }, 

    { 
        icon: require('@/assets/images/icons/Hamburger.png'), 
        label: 'Hamburger'
    }
]);

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', // Adjust the height as per your design
    width: '100%',
    borderRadius: '8px'
});

const activeIndex = ref(0);

const setActive = (index: any) => {
    activeIndex.value = index;
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/logos/foodcart.png'),
                width: "48",
                height: "48"
              }, null, 8, ["imageUrl"]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex items-center gap-[16px] bg-grey-50 rounded-[8px] p-[2px] text-body-2" }, [
          _createElementVNode("div", { class: "bg-white rounded-[8px] px-[20px] py-[8px] cursor-pointer" }, "Delivery"),
          _createElementVNode("div", { class: "px-[20px] py-[8px] cursor-pointer" }, "Pickup")
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(InputField, {
            type: "text",
            prefixIcon: "ant-design:search-outlined"
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(ActionButton, {
            theme: "outlined",
            prefixIcon: "material-symbols:location-on-outline",
            label: "Fajuyi Way, Ikeja GRA...",
            class: "w-fit whitespace-nowrap"
          })
        ])
      ])
    ]),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "w-fit",
            onClick: ($event: any) => (setActive(index))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['w-[60px] h-[60px] p-[14px] rounded-full', { 'bg-[#D7804C33]': activeIndex.value === index }])
            }, [
              _createVNode(ImageCard, {
                imageUrl: item.icon,
                width: "32",
                height: "32",
                class: "w-fit mx-auto"
              }, null, 8, ["imageUrl"])
            ], 2),
            _createElementVNode("div", _hoisted_9, _toDisplayString(item.label), 1)
          ], 8, _hoisted_8))
        }), 128))
      ]),
      _createElementVNode("section", _hoisted_10, [
        _createElementVNode("header", _hoisted_11, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-[28px] font-semibold font-sourceSerifPro" }, "Popular restaurants near you", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_unref(Icon), {
                icon: "material-symbols:arrow-left-alt",
                width: "24",
                height: "24",
                class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_unref(Icon), {
                icon: "material-symbols:arrow-right-alt",
                width: "24",
                height: "24",
                class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
              })
            ])
          ])
        ]),
        _createElementVNode("main", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restaurants.value, (restaurant, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "border-[1px] border-grey-50 p-[12px] rounded-[8px] min-w-[278px]"
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle(getBackgroundStyle(restaurant.imageUrl))
              }, null, 4),
              _createElementVNode("div", _hoisted_16, _toDisplayString(restaurant.name), 1),
              _createElementVNode("div", _hoisted_17, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-link text-medium" }, "Location:", -1)),
                _createTextVNode(" " + _toDisplayString(restaurant.location), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:star-filled",
                  width: "24",
                  height: "24",
                  class: "text-[#FADB14]"
                }),
                _createElementVNode("div", _hoisted_19, _toDisplayString(restaurant.reviews) + " reviews", 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("section", _hoisted_20, [
        _cache[5] || (_cache[5] = _createElementVNode("header", { class: "my-[37px] items-center" }, [
          _createElementVNode("div", { class: "text-[28px] font-semibold font-sourceSerifPro" }, "All restaurants near you")
        ], -1)),
        _createElementVNode("main", _hoisted_21, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restaurants.value, (restaurant, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "border-[1px] border-grey-50 p-[12px] rounded-[8px] min-w-[278px]"
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle(getBackgroundStyle(restaurant.imageUrl))
              }, null, 4),
              _createElementVNode("div", _hoisted_22, _toDisplayString(restaurant.name), 1),
              _createElementVNode("div", _hoisted_23, [
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-link text-medium" }, "Location:", -1)),
                _createTextVNode(" " + _toDisplayString(restaurant.location), 1)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:star-filled",
                  width: "24",
                  height: "24",
                  class: "text-[#FADB14]"
                }),
                _createElementVNode("div", _hoisted_25, _toDisplayString(restaurant.reviews) + " reviews", 1)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("footer", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createVNode(ImageCard, {
            imageUrl: require('@/assets/images/logos/foodcart.png'),
            width: "48",
            height: "48"
          }, null, 8, ["imageUrl"]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
        ]),
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"flex gap-[120px] text-grey-900 ml-auto\"><div class=\"\"><div class=\"text-heading-6 font-medium\">COMPANY</div><div class=\"text-caption mt-[24px] grid gap-[14px]\"><div class=\"\">Home</div><div class=\"\">How it works</div><div class=\"\">Faq</div><div class=\"\">Blog</div></div></div><div class=\"\"><div class=\"text-heading-6 font-medium\">GET HELP</div><div class=\"text-caption mt-[24px] grid gap-[14px]\"><div class=\"\">Contact Us</div><div class=\"\">companymail@gmail.com</div><div class=\"\">+123456789</div></div></div><div class=\"\"><div class=\"text-heading-6 font-medium\">LEGAL</div><div class=\"text-caption mt-[24px] grid gap-[14px]\"><div class=\"\">Privacy Policy</div><div class=\"\">Terms of service</div><div class=\"\">Cookie settings</div></div></div></div>", 1))
      ]),
      _createElementVNode("div", _hoisted_29, [
        _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"flex gap-[24px]\"><div class=\"\">© 2024 Relume. All rights reserved.</div><div class=\"underline\">Privacy Policy</div><div class=\"underline\">Terms of Service</div><div class=\"underline\">Cookie settings</div></div>", 1)),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:twitter-outlined",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:linkedin-filled",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:discord-outlined",
              width: "20",
              height: "20",
              class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ])
        ])
      ])
    ])
  ], 64))
}
}

})