<template>
  <div>
    <div class="entity-image rounded-[8px]" :style="imageStyle"></div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'

interface Props {
    imageUrl?: string | undefined
    width?: string
    height?: string
}

const props = defineProps<Props>();

const imageUrlPath = ref<string | undefined>(undefined);

watch(() => props.imageUrl, (newValue) => {
    if (newValue) {
        imageUrlPath.value = newValue;
        updateImageStyle();
    }
});

const imageStyle = ref({
    width: `${props.width ?? '340'}px`,
    height: `${props.height ?? '340'}px`,
    backgroundImage: `url(${props.imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

function updateImageStyle() {
    imageStyle.value.backgroundImage = `url(${imageUrlPath.value})`;
}
</script>

<style scoped>

</style>
