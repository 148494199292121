import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-body-2 text-grey-200 mb-[7px] inline-block"
}
const _hoisted_2 = { class: "flex gap-[12px] border-solid border-[1px] border-grey-50 py-[10px] px-[12px] rounded-[6px]" }
const _hoisted_3 = ["name", "placeholder", "value", "id"]

import { VNode } from 'vue';
import { Icon, IconifyIcon } from '@iconify/vue'

interface Props {
    label?: VNode | string
    undertext?: string
    prefixIcon?: IconifyIcon | any
    suffixIcon?: IconifyIcon | any
    placeholder?: string
    name?: string | number | any
    value?: string | number | any
    id?: string | number | any
    handleClick?: (event: MouseEvent) => void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextArea',
  props: {
    label: {},
    undertext: {},
    prefixIcon: {},
    suffixIcon: {},
    placeholder: {},
    name: {},
    value: {},
    id: {},
    handleClick: { type: Function }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.prefixIcon)
        ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 0,
            class: "text-grey-200",
            icon: _ctx.prefixIcon,
            width: "24",
            height: "24"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("textarea", {
        class: "w-[100%] outline-none h-[204px] placeholder:text-body-2 text-body-2",
        name: _ctx.name,
        placeholder: _ctx.placeholder,
        value: _ctx.value,
        id: _ctx.id,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:value', $event))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:blur:value', $event)))
      }, null, 40, _hoisted_3),
      (_ctx.suffixIcon)
        ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 1,
            class: "text-grey-200 cursor-pointer",
            icon: _ctx.suffixIcon,
            width: "24",
            height: "24",
            onClick: _ctx.handleClick
          }, null, 8, ["icon", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})