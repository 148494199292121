import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid bg-[#1E1E1E] lg:grid-cols-2 lg:px-[60px] px-[20px] lg:py-[60px] py-[40px]" }
const _hoisted_2 = { class: "lg:flex lg:gap-[120px] grid grid-cols-2 gap-[40px] text-white lg:ml-auto lg:mt-0 mt-[50px]" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "text-caption mt-[24px] grid gap-[14px]" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "text-caption mt-[24px] grid gap-[14px]" }
const _hoisted_7 = { class: "bg-black text-white lg:px-[60px] py-[34px] lg:flex items-center lg:justify-between text-caption" }
const _hoisted_8 = { class: "lg:flex text-center gap-[24px]" }
const _hoisted_9 = { class: "text-white flex gap-[16px] lg:mt-0 mt-[20px] w-fit lg:ml-auto mx-auto" }
const _hoisted_10 = {
  href: "https://x.com/foodcartng",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}
const _hoisted_11 = {
  href: "https://www.facebook.com/foodcart.tech",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}
const _hoisted_12 = {
  href: "https://www.youtube.com/channel/UCegkuRFbZMXD2rOJhtFg2Kg",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}
const _hoisted_13 = {
  href: "https://www.instagram.com/foodcart.ng/",
  target: "_blank",
  rel: "noopener noreferrer",
  class: "w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative"
}

import { Icon } from '@iconify/vue';
import ImageCard from '../image/ImageCard.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "flex items-center gap-[6px] bg-white w-fit py-[8px] px-[20px] rounded-[50px] h-fit"
      }, {
        default: _withCtx(() => [
          _createVNode(ImageCard, {
            imageUrl: require('@/assets/images/logos/foodcart.png'),
            width: "48",
            height: "48"
          }, null, 8, ["imageUrl"]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-itim lg:text-heading-5" }, "Foodcart", -1))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-heading-6 font-medium" }, "COMPANY", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: "/",
              class: ""
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Home")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "about-us",
              class: ""
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("How it works")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "blog",
              class: ""
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Blog")
              ])),
              _: 1
            })
          ])
        ]),
        _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"\"><div class=\"text-heading-6 font-medium\">GET HELP</div><div class=\"text-caption mt-[24px] grid gap-[14px]\"><div class=\"\">Contact Us</div><div><a href=\"tel:+2349166190024\" class=\"\">+234 916 619 0024</a></div><div><a href=\"mailto:Sales@foodcart.ng\" class=\"\">Sales@foodcart.ng</a></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_5, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-heading-6 font-medium" }, "LEGAL", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: "/privacy-policy",
              class: ""
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Privacy Policy")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: "/terms-of-services",
              class: ""
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("div", { class: "" }, "Terms of service", -1)
              ])),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "" }, "Cookie settings", -1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "" }, "© 2024 FoodCart. All rights reserved.", -1)),
        _createVNode(_component_router_link, {
          to: "/privacy-policy",
          class: ""
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("div", { class: "underline" }, "Privacy Policy", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/terms-of-services",
          class: ""
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("div", { class: "underline" }, "Terms of Service", -1)
          ])),
          _: 1
        }),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "underline" }, "Cookie settings", -1))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:twitter-outlined",
            width: "20",
            height: "20",
            class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          })
        ]),
        _createElementVNode("a", _hoisted_11, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:facebook-filled",
            width: "20",
            height: "20",
            class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          })
        ]),
        _createElementVNode("a", _hoisted_12, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:youtube-outlined",
            width: "20",
            height: "20",
            class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          })
        ]),
        _createElementVNode("a", _hoisted_13, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:instagram-outlined",
            width: "20",
            height: "20",
            class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          })
        ])
      ])
    ])
  ]))
}
}

})