<template>
    <section class="lg:px-[60px] px-[20px] lg:mt-[120px] mt-[60px]">
        <header class="">
            <div class="lg:text-[44px] text-[30px] font-semibold font-sourceSerifPro">FAQs</div>
            <div class="lg:text-sub-heading text-body-2 text-grey-200">Questions? We've got answers.</div>
        </header>

        <main class="mt-[32px]">
            <div v-for="(faq, index) in faqs" :key="index" class="flex flex-col">
                <div class="flex items-center lg:text-body-1 text-caption justify-between border-b-[1px] border-[#0000000F] py-[24px]" @click="toggleDescription(index)">
                    <div class="cursor-pointer">{{ faq.question }}</div>
                    <Icon icon="ant-design:caret-down-outlined" width="24" height="24" />
                </div>
                <transition name="fade">
                    <div v-if="activeIndex === index" class="py-[16px] text-grey-200 text-caption lg:w-[1000px]">
                        {{ faq.description }}
                    </div>
                </transition>
            </div>
        </main>
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Icon } from '@iconify/vue';

const activeIndex = ref<number | null>(null);

const faqs = [
    { 
        question: "How can FoodCart register help my business grow?", 
        description: "FoodCart register system will help you gather data that will help you understand your customers and restaurant much more better. With the FoodCart register system, you are able to understand market trends, your restaurant trends, what kind of food your customers like, your customers and more." 
    },
    // { 
    //     question: "Is FoodCart's register system free?", 
    //     description: "The FoodCart register system is for free. The device will be given to you at zero fee, the cost on the internet on the device will be fully paid by FoodCart. FoodCart will only charge per transaction run on the device." 
    // },
    { 
        question: "What is a FoodCart register/POS system?", 
        description: "FoodCart register/POS system is an essential device needed by the restaurant to help keep proper sales records, give an analytic report on everything going on in terms of sales. With FoodCart's register/POS system, you can't only monitor your restaurant remotely, but also help you make swift decisions in order to grow a profitable restaurant business." 
    },
];

function toggleDescription(index: number) {
    activeIndex.value = activeIndex.value === index ? null : index;
}
</script>

<style scoped>
/* Add any additional styles here */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>