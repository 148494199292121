import { reactive } from "vue";
import {set} from "lodash";

interface ValidationError {
    path: string;
    errors: string[];
}

export const useForm = ({ initialValues = {}, validationSchema, onSubmit }: any) => {
  const values = reactive(initialValues);
  const errors: any = reactive({});
  const touched: any = reactive({});
  const form = reactive({
    loading: false,
    hasErrors: false,
    res: undefined,
  });
  const ret: any = reactive({});
  
  const setRes = (value: any) => {
    form.res = value;
  };

  const setValues = (newValues: any) => {
    // Update each value in the form with the new values
    for (const key in newValues) {
      if (Object.prototype.hasOwnProperty.call(newValues, key)) {
        values[key] = newValues[key];
      }
    }
  };

  const handleSubmit = async (event: any, optionalData?: any) => {
    event.preventDefault();

    if (validationSchema) {
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        
        Object.assign(errors, undefined);
        form.loading = true;
        await onSubmit(values);
        form.loading = false;
        setRes(values)

      } catch (err: any) {
        for (const error of (err.inner as ValidationError[])) {
          errors[error.path] = error.errors[0];
        }
      }

      return;
    }

    return await onSubmit(values, optionalData);
  };

  const handleValidation = (name: string, value: string) => {
    if (validationSchema) {
      try {
        validationSchema.validateSyncAt(name, { [name]: value });
        delete errors[name];
      } catch (error: any) {
        errors[name] = error.errors[0];
      }
      form.hasErrors = !!Object.keys(errors).length;
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    set(values, event.target.name, event.target.value)

    if (validationSchema) {
      handleValidation(name, value);
    }
  };

  const handleFileChange = (event: any) => {
    const { name, files } = event.target;

    set(values, event.target.name, event.target.files?.[0])

    if (validationSchema) {
      handleValidation(name, files?.[0]);
    }
  };

  const handleBlur = (event: any) => {
    const { name, value } = event.target;

    touched[name] = true;

    if (validationSchema) {
      handleValidation(name, value);
    }
  };

  ret["handleSubmit"] = handleSubmit;
  ret["handleChange"] = handleChange;
  ret["handleFileChange"] = handleFileChange;
  ret["handleBlur"] = handleBlur;
  ret["setValues"] = setValues;
  ret["values"] = values;
  ret["errors"] = errors;
  ret["touched"] = touched;
  ret["form"] = form;

  return ret;
};
