<template>
    <div :style="imageStyle" class="lg:px-[60px] py-[24px] relative">
        <div class="absolute inset-0 bg-black opacity-50"></div>

        <div class="lg:px-[60px] px-[20px]">
            <div class="shadow-md rounded-[200px] bg-white relative">
                <AppNavbar />
            </div>
        </div>
        
        <div class="px-[60px] font-sourceSerifPro lg:text-[80px] text-[40px] text-center lg:mt-[160px] mt-[80px] text-white font-bold relative">
            <div class="">Grow your</div>
            <div class="lg:flex justify-center gap-[20px]">
                <div class="bg-white text-primary-300 px-[20px] rounded-[16px] rotate-[-2.5deg]">Restaurant</div>
                <div class="">Business.</div>
            </div>
        </div>

        <ImageCard :imageUrl="require('@/assets/images/icons/16.png')" width="140" height="140" class="absolute top-[200px] right-[60px] lg:block hidden" />
        <ImageCard :imageUrl="require('@/assets/images/icons/17.png')" width="140" height="140" class="absolute bottom-[50px] left-[60px] lg:block hidden" />

        <div class="px-[60px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 mt-[40px] text-[#FFFFFFBF] relative">
            With <span class="text-white font-bold underline">Foodcart's</span> restaurant management platform, Take charge with
            our all-in-one solution. Join us now to streamline your operations and take control of your business!
        </div>

        <div class="w-fit mx-[auto] flex items-center gap-[20px] mt-[32px] relative">
            <router-link to="/registration"><ActionButton theme="primary" label="Get started" class="w-fit" /></router-link>
            <router-link to="/about-us"><ActionButton theme="outlined" label="Learn More" class="w-fit border-white text-white" /></router-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppNavbar from '../../../../shared/navbar/AppNavbar.vue';
import { defineProps, ref, watch } from 'vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';

const props = defineProps<{ imageUrl: string | undefined }>();
const imageUrlPath = ref<string | undefined>(undefined);

watch(() => props.imageUrl, (newValue) => {
    if (newValue) {
        imageUrlPath.value = newValue;
        updateImageStyle();
    }
});

const imageStyle = ref({
    height: '100vh',
    backgroundImage: `url(${props.imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

function updateImageStyle() {
    imageStyle.value.backgroundImage = `url(${imageUrlPath.value})`;
}
</script>

<style lang="scss" scoped>

</style>