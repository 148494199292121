import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px] mt-[40px]" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "grid lg:grid-cols-2 items-center lg:justify-between lg:gap-[82px]" }
const _hoisted_4 = { class: "grid gap-[16px] lg:mt-0 mt-[20px]" }
const _hoisted_5 = { class: "lg:px-[60px] lg:py-[100px] py-[30px]" }
const _hoisted_6 = { class: "lg:mt-[62px] mt-[32px]" }
const _hoisted_7 = {
  class: "relative lg:rounded-[60px]",
  style: {"width":"100%","height":"404px","background-image":"url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Ffront-view-woman-working-service-industry_23-2150722810.jpg&f=1&nofb=1&ipt=8c34bc878e89159fe8622dc2fb0b3c444171ef09613564faf015a6af8e1290e9&ipo=images')","background-repeat":"no-repeat","background-size":"cover","background-position":"center"}
}
const _hoisted_8 = { class: "text-white absolute top-[72px] lg:left-[80px] left-[20px]" }

import AppWrapper from '../../components/app/app-wrapper/AppWrapper.vue'
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppFaq from '@/components/shared/app-faq/AppFaq.vue';

// const testimonials = ref([
//     {
//         imageUrl: 'https://as1.ftcdn.net/v2/jpg/04/95/85/62/1000_F_495856243_oEH1nXFgcA7yBe1E6V0jAOv2efs1wMzj.jpg',
//         rating: 5,
//         text: "Our restaurant management platform has revolutionized the way we run our business. It's efficient, user-friendly, and has increased our revenue significantly.",
//         author: 'John Doe',
//         position: 'Owner, ABC Restaurant'
//     },
// ]);

// function getBackgroundStyle(imageUrl: string) {
//     return {
//         minWidth: '392px',
//         height: '608px',
//         borderRadius: '20px',
//         backgroundImage: `url(${imageUrl})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//     };
// }


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

// import { ref } from 'vue';
// import StarRating from '../../components/shared/ratings/StarRating.vue'
// import { Icon } from '@iconify/vue';

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(AppWrapper, null, {
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createElementVNode("section", { class: "lg:px-[60px] lg:py-[100px] p-[20px]" }, [
        _createElementVNode("header", { class: "" }, [
          _createElementVNode("div", { class: "lg:text-[44px] text-[32px] font-semibold font-sourceSerifPro" }, "How it works"),
          _createElementVNode("div", { class: "font-sub-heading text-grey-200" }, "Discover the simplicity behind it all and see for yourself.")
        ]),
        _createElementVNode("main", { class: "mt-[62px]" }, [
          _createElementVNode("div", {
            class: "relative",
            style: {"width":"100%","height":"536px","border-radius":"0px 60px 0px 60px","background-image":"url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fside-view-woman-working-service-industry_23-2150722790.jpg&f=1&nofb=1&ipt=a553054b77f81cc1da3aac94ddfca8a46983a1f94a50be519919c59e563674b4&ipo=images')","background-repeat":"no-repeat","background-size":"cover","background-position":"center"}
          }, [
            _createElementVNode("div", {
              class: "absolute inset-0 h-[70%] top-[auto]",
              style: {"background":"linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))","border-radius":"0px 0 0px 60px"}
            }),
            _createElementVNode("div", { class: "text-white lg:w-[550px] absolute bottom-[40px] lg:left-[40px] px-[20px]" }, [
              _createElementVNode("div", { class: "text-heading-4 my-[12px] font-bold" }, " Effortlessly manage your online orders with our custom microsite "),
              _createElementVNode("div", { class: "text-[#FFFFFFCC] font-medium" }, " Our restaurant management platform simplifies your daily tasks, allowing you to focus on what matters most - serving your customers. ")
            ])
          ])
        ])
      ], -1)),
      _cache[6] || (_cache[6] = _createElementVNode("section", null, [
        _createElementVNode("header", { class: "text-center lg:text-[44px] text-[26px] font-bold font-sourceSerifPro lg:mt-0 mt-[20px]" }, [
          _createElementVNode("div", null, [
            _createTextVNode(" Streamline your restaurant operations "),
            _createElementVNode("br"),
            _createTextVNode(" with our intuitive "),
            _createElementVNode("span", { class: "text-primary-300" }, "microsite"),
            _createTextVNode(" and "),
            _createElementVNode("br"),
            _createElementVNode("span", { class: "text-primary-300" }, "register app.")
          ])
        ])
      ], -1)),
      _createElementVNode("section", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("header", { class: "" }, [
          _createElementVNode("div", { class: "lg:text-[44px] text-[32px] font-semibold font-sourceSerifPro" }, "Contact Us"),
          _createElementVNode("div", { class: "font-sub-heading text-grey-200" }, "Have a question? Get in touch with us!")
        ], -1)),
        _createElementVNode("main", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(InputField, {
                placeholder: "Your Name",
                type: "text",
                prefixIcon: "ant-design:user-outlined"
              }),
              _createVNode(InputField, {
                placeholder: "Your Email Address",
                type: "email",
                prefixIcon: "ant-design:mail-outlined"
              }),
              _createVNode(InputField, {
                placeholder: "Your Phone number",
                type: "tel",
                prefixIcon: "ant-design:phone-outlined"
              }),
              _createVNode(DropdownField, {
                defaultOptionLabel: "Select a topic",
                optionsData: "",
                class: "bg-black text-white rounded-[8px]"
              }),
              _createVNode(TextArea, { placeholder: "Type a meessage..." })
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", {
              class: "lg:block hidden",
              style: {"width":"100%","height":"574px","border-radius":"0px 60px 0px 60px","background-image":"url('https://img.freepik.com/premium-photo/office-black-people-workers-sitting-desks-black-man_1124848-142113.jpg?w=1380')","background-repeat":"no-repeat","background-size":"cover","background-position":"center"}
            }, null, -1))
          ]),
          _createVNode(ActionButton, {
            label: "Submit Message",
            theme: "primary",
            class: "w-fit lg:mt-0 mt-[20px]"
          })
        ])
      ]),
      _createVNode(AppFaq, { id: "faq" }),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("main", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("div", {
              class: "absolute inset-0 lg:rounded-[60px] rounded-0",
              style: {"background":"linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_8, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "lg:text-heading-2 text-heading-5 my-[12px] font-bold" }, [
                _createTextVNode(" Streamline your restaurant "),
                _createElementVNode("br"),
                _createTextVNode(" operations ")
              ], -1)),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-[#FFFFFFCC] text-sub-heading font-medium mt-[16px] mb-[24px]" }, [
                _createTextVNode(" Discover our restaurant management platform and boost your business "),
                _createElementVNode("br"),
                _createTextVNode(" efficiency. ")
              ], -1)),
              _createVNode(_component_router_link, { to: "/registration" }, {
                default: _withCtx(() => [
                  _createVNode(ActionButton, {
                    label: "Get Started",
                    theme: "outlined",
                    class: "w-fit text-white border-white"
                  })
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})