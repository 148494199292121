import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-body-2 text-grey-200 mb-[7px] inline-block capitalize"
}
const _hoisted_2 = { class: "flex items-center gap-[12px] border-solid border-[1px] bg-white border-grey-50 lg:py-[10px] py-[6px] px-[12px] rounded-[6px]" }
const _hoisted_3 = ["type", "name", "placeholder", "value", "id", "min", "max"]
const _hoisted_4 = {
  key: 1,
  class: "text-caption text-medium text-red-500 mt-[4px]"
}
const _hoisted_5 = {
  key: 2,
  class: "text-caption text-medium text-grey-200 mt-[4px]"
}

import { VNode } from 'vue';
import { Icon, IconifyIcon } from '@iconify/vue'

interface Props {
    type: string
    label?: VNode | string
    undertext?: string
    prefixIcon?: IconifyIcon | any
    suffixIcon?: IconifyIcon | any
    placeholder?: string
    name?: string | number | any
    min?: any
    max?: any
    value?: string | number | any
    id?: string | number | any
    handleClick?: (event: MouseEvent) => void
    errors?: string 
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InputField',
  props: {
    type: {},
    label: {},
    undertext: {},
    prefixIcon: {},
    suffixIcon: {},
    placeholder: {},
    name: {},
    min: {},
    max: {},
    value: {},
    id: {},
    handleClick: { type: Function },
    errors: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.prefixIcon)
        ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 0,
            class: "text-grey-200 lg:w-[20px] lg:h-[20px]",
            icon: _ctx.prefixIcon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        class: "w-[100%] outline-none placeholder:text-caption text-body-2",
        type: _ctx.type,
        name: _ctx.name,
        placeholder: _ctx.placeholder,
        value: _ctx.value,
        id: _ctx.id,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:value', $event))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:blur:value', $event))),
        min: _ctx.min,
        max: _ctx.max
      }, null, 40, _hoisted_3),
      (_ctx.suffixIcon)
        ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 1,
            class: "text-grey-200 cursor-pointer lg:w-[20px] lg:h-[20px]",
            icon: _ctx.suffixIcon,
            onClick: _ctx.handleClick
          }, null, 8, ["icon", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.errors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errors), 1))
      : _createCommentVNode("", true),
    (_ctx.undertext)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.undertext), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})