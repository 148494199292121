<template>
    <div v-show="props.openModal" class="modal-overlay">
        <div class="modal-content lg:max-h-[90vh] h-full overflow-auto relative no-scrollbar">
            <header class="flex items-center justify-between border-b-[1px] border-solid border-grey-50 p-[24px] fixed lg:w-[700px] w-full bg-white z-[1]">
                <h3 class="font-semibold text-sub-heading">{{ headerContent }}</h3>
                <button @click="closeModal">
                    <Icon icon="ant-design:close-outlined" />
                </button>
            </header>

            <div class="mt-[60px]">
                <slot></slot>
            </div>

            <footer v-if="defaultActionButton" class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px] ">
                <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" />
                <ActionButton label="Save" theme="primary" class="w-[fit-content]" :type="isSubmit" :handleClick="actionBtnHandleClick" />
            </footer>
        </div>
    </div>
</template>
  
<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { VNode, defineProps, defineEmits } from 'vue';
import ActionButton from '../action-button/ActionButton.vue';

interface Props {
    headerContent: VNode | string
    openModal: boolean 
    defaultActionButton?: boolean
    actionBtnHandleClick?: () => void
    isSubmit?: boolean
}

const props = defineProps<Props>()

const emits = defineEmits(['close']);

const closeModal = () => {
    emits('close');
};

</script>

<style scoped>

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .modal-content {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 700px;

        header {
            border-radius: 8px 8px 0 0;
        }
    }
}

</style>
