import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "text-body-2 text-grey-200 mb-[7px] inline-block"
}
const _hoisted_3 = { class: "border-[1px] border-solid border-grey-50 px-[16px] rounded-[6px]" }
const _hoisted_4 = ["name", "value", "multiple"]
const _hoisted_5 = ["value"]

import { VNode } from 'vue';

interface Props {
    optionsData: Array<number | string | VNode> | any
    value?: string
    label?: string
    defaultOptionLabel?: string
    name?: string
    multiple?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownField',
  props: {
    optionsData: {},
    value: {},
    label: {},
    defaultOptionLabel: {},
    name: {},
    multiple: { type: Boolean }
  },
  emits: ['change'],
  setup(__props: any) {






return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("select", {
        name: _ctx.name,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event))),
        value: _ctx.value,
        multiple: _ctx.multiple,
        class: "border-none outline-none bg-transparent w-[100%] lg:py-[14px] py-[8px] font-medium text-grey-200 text-caption"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsData, (data, idx) => {
          return (_openBlock(), _createElementBlock("option", {
            key: idx,
            value: data.value
          }, _toDisplayString(data.name), 9, _hoisted_5))
        }), 128))
      ], 40, _hoisted_4)
    ])
  ]))
}
}

})