import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px]" }
const _hoisted_2 = { class: "shadow-md rounded-[200px] bg-white relative" }
const _hoisted_3 = { class: "w-fit mx-[auto] flex items-center gap-[20px] mt-[32px] relative" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppNavbar from '../../../../shared/navbar/AppNavbar.vue';
import { ref, watch } from 'vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeroSection',
  props: {
    imageUrl: {}
  },
  setup(__props: any) {

const props = __props;
const imageUrlPath = ref<string | undefined>(undefined);

watch(() => props.imageUrl, (newValue) => {
    if (newValue) {
        imageUrlPath.value = newValue;
        updateImageStyle();
    }
});

const imageStyle = ref({
    height: '100vh',
    backgroundImage: `url(${props.imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

function updateImageStyle() {
    imageStyle.value.backgroundImage = `url(${imageUrlPath.value})`;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(imageStyle.value),
    class: "lg:px-[60px] py-[24px] relative"
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(AppNavbar)
      ])
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"px-[60px] font-sourceSerifPro lg:text-[80px] text-[40px] text-center lg:mt-[160px] mt-[80px] text-white font-bold relative\"><div class=\"\">Grow your</div><div class=\"lg:flex justify-center gap-[20px]\"><div class=\"bg-white text-primary-300 px-[20px] rounded-[16px] rotate-[-2.5deg]\">Restaurant</div><div class=\"\">Business.</div></div></div>", 1)),
    _createVNode(ImageCard, {
      imageUrl: require('@/assets/images/icons/16.png'),
      width: "140",
      height: "140",
      class: "absolute top-[200px] right-[60px] lg:block hidden"
    }, null, 8, ["imageUrl"]),
    _createVNode(ImageCard, {
      imageUrl: require('@/assets/images/icons/17.png'),
      width: "140",
      height: "140",
      class: "absolute bottom-[50px] left-[60px] lg:block hidden"
    }, null, 8, ["imageUrl"]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "px-[60px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 mt-[40px] text-[#FFFFFFBF] relative" }, [
      _createTextVNode(" With "),
      _createElementVNode("span", { class: "text-white font-bold underline" }, "Foodcart's"),
      _createTextVNode(" restaurant management platform, Take charge with our all-in-one solution. Join us now to streamline your operations and take control of your business! ")
    ], -1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: "/registration" }, {
        default: _withCtx(() => [
          _createVNode(ActionButton, {
            theme: "primary",
            label: "Get started",
            class: "w-fit"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/about-us" }, {
        default: _withCtx(() => [
          _createVNode(ActionButton, {
            theme: "outlined",
            label: "Learn More",
            class: "w-fit border-white text-white"
          })
        ]),
        _: 1
      })
    ])
  ], 4))
}
}

})