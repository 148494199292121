import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px]" }
const _hoisted_2 = { class: "shadow-md rounded-[200px] bg-white relative" }
const _hoisted_3 = { class: "lg:px-[60px] mt-[60px] mb-[-30px]" }
const _hoisted_4 = { class: "grid lg:grid-cols-4 lg:gap-[32px] gap-[20px] px-[20px]" }
const _hoisted_5 = { class: "px-[32px] pt-[32px] text-center" }
const _hoisted_6 = { class: "font-semibold mt-[12px] text-heading-4" }
const _hoisted_7 = { class: "my-[20px] text-grey-200" }
const _hoisted_8 = { class: "w-fit mx-auto pb-[32px]" }
const _hoisted_9 = { class: "mt-[48px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppFooter from '@/components/shared/app-footer/AppFooter.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import AppNavbar from '@/components/shared/navbar/AppNavbar.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsView',
  setup(__props) {

// import ActionButton from '@/components/shared/action-button/ActionButton.vue';
// import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
const imageStyle = ref({
    height: '50vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Fpremium-photo%2Fblack-woman-cashier-portrait-with-cash-register-management-small-business-leadership_590464-254906.jpg&f=1&nofb=1&ipt=58eae57b2dae5a3cea1ff041d683b819274b2ebd5462703c6abc5dcacbec2440&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const restaurants = ref([
    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Mart',
        location: "The FoodCart Mart is a mini website for both online(ghost) and physical restaurants to create a better ordering experience for their customers. It will help create a faster ordering process, carry out payment seamlessly, keep record and provide analytical sales report and more.",
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Register',
        location: "FoodCart register is a smart device that helps manage a restaurant with the use of adequate and concise data to scale the restaurant at zero to no cost. With our register system, profitability can be increased, record all transactions, understand your customers well and more.",
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Consultancy',
        location: "At FoodCart consult, we've been able to create and master solutions with the help of professionals that uses sophisticated tools developed by FoodCart to not only restructure but position a restaurant to be on the path to profitability.",
    },
]);

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', 
    width: '100%',
    borderRadius: '8px 8px 0 0'
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle(imageStyle.value),
      class: "lg:px-[60px] py-[24px] relative"
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AppNavbar)
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-sourceSerifPro lg:text-[80px] text-[45px] text-center lg:mt-[100px] mt-[75px] text-white font-bold relative" }, [
        _createElementVNode("div", { class: "px-[60px] lg:flex justify-center gap-[20px]" }, [
          _createElementVNode("div", { class: "" }, "Products")
        ])
      ], -1)),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/16.png'),
        width: "140",
        height: "140",
        class: "absolute top-[200px] right-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/17.png'),
        width: "140",
        height: "140",
        class: "absolute bottom-[50px] left-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"])
    ], 4),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restaurants.value, (restaurant, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "border-[1px] border-grey-50 rounded-[8px]"
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(getBackgroundStyle(restaurant.imageUrl))
            }, null, 4),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(restaurant.name), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(restaurant.location), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_link, { to: "/registration" }, {
                default: _withCtx(() => [
                  _createVNode(ActionButton, {
                    theme: "primary",
                    label: "Register Now",
                    class: "rounded-[99.9px]"
                  })
                ]),
                _: 1
              })
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(AppFooter)
    ])
  ]))
}
}

})