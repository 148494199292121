<template>
    <div class="flex items-center justify-between lg:px-[24px] lg:py-[18px] px-[16px] py-[12px] w-[100%] relative">
        <router-link to="/">
            <div class="flex items-center gap-[6px]">
                <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48" />
                <div class="font-itim text-heading-5">Foodcart</div>
            </div>
        </router-link>

        <div class="lg:flex items-center text-grey-200 text-body-2 font-medium lg:block hidden">
            <router-link to="/" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/' }">Home</router-link>
            <router-link to="/products" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/products' }">Products</router-link>
            <router-link to="/blog" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/blog' }">Blog</router-link>
            <a href="javascript:void(0)" @click="goToFaq" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/faq' }">FAQ</a>
            <router-link to="/about-us" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/about-us' }">About Us</router-link>
        </div>

        <div class="w-fit lg:block hidden">
            <router-link to="/registration">
                <ActionButton theme="primary" label="Register Now" class="rounded-[99.9px]" />
            </router-link>
        </div>

        <div class="border-[1px] border-grey-50 p-[10px] rounded-[8px] lg:hidden" @click="toggleDropdown">
            <Icon icon="ant-design:menu-outlined" width="18" height="18" />
        </div>
    </div>

    <!-- Dropdown menu for mobile -->
    <div v-if="isDropdownOpen" class="absolute top-full left-0 w-full bg-white text-white z-10 mt-2 py-[12px] rounded-lg shadow-lg">
        <div class="flex flex-col items-start text-grey-200 text-body-2 font-medium">
            <router-link to="/" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/' }">Home</router-link>
            <router-link to="/products" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/products' }">Products</router-link>
            <router-link to="/blog" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/blog' }">Blog</router-link>
            <a href="javascript:void(0)" @click="goToFaq" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/faq' }">FAQ</a>
            <router-link to="/about-us" class="px-[24px] py-[8px]" :class="{ 'text-grey-300 underline': activeLink === '/about-us' }">About Us</router-link>
            <router-link to="/registration" class="px-[10px] mt-5">
                <ActionButton theme="primary" label="Register Now" class="rounded-[99.9px]" />
            </router-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
import ActionButton from '../action-button/ActionButton.vue';
import ImageCard from '../image/ImageCard.vue';
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';

defineProps<{ activeLink?: string }>();

const isDropdownOpen = ref(false); 
const router = useRouter();

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value; 
};

const goToFaq = () => {
    router.push('/').then(() => {
        const faqSection = document.getElementById('faq');
        if (faqSection) {
            faqSection.scrollIntoView({ behavior: 'smooth' });
        }
    });
};
</script>

<style lang="scss" scoped>

</style>
