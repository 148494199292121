import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, watch } from 'vue'

interface Props {
    imageUrl?: string | undefined
    width?: string
    height?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageCard',
  props: {
    imageUrl: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const props = __props;

const imageUrlPath = ref<string | undefined>(undefined);

watch(() => props.imageUrl, (newValue) => {
    if (newValue) {
        imageUrlPath.value = newValue;
        updateImageStyle();
    }
});

const imageStyle = ref({
    width: `${props.width ?? '340'}px`,
    height: `${props.height ?? '340'}px`,
    backgroundImage: `url(${props.imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

function updateImageStyle() {
    imageStyle.value.backgroundImage = `url(${imageUrlPath.value})`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "entity-image rounded-[8px]",
      style: _normalizeStyle(imageStyle.value)
    }, null, 4)
  ]))
}
}

})