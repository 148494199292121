import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px] lg:mt-[120px] mt-[60px]" }
const _hoisted_2 = { class: "mt-[32px]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "cursor-pointer" }
const _hoisted_5 = {
  key: 0,
  class: "py-[16px] text-grey-200 text-caption lg:w-[1000px]"
}

import { ref } from 'vue';
import { Icon } from '@iconify/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFaq',
  setup(__props) {

const activeIndex = ref<number | null>(null);

const faqs = [
    { 
        question: "How can FoodCart register help my business grow?", 
        description: "FoodCart register system will help you gather data that will help you understand your customers and restaurant much more better. With the FoodCart register system, you are able to understand market trends, your restaurant trends, what kind of food your customers like, your customers and more." 
    },
    // { 
    //     question: "Is FoodCart's register system free?", 
    //     description: "The FoodCart register system is for free. The device will be given to you at zero fee, the cost on the internet on the device will be fully paid by FoodCart. FoodCart will only charge per transaction run on the device." 
    // },
    { 
        question: "What is a FoodCart register/POS system?", 
        description: "FoodCart register/POS system is an essential device needed by the restaurant to help keep proper sales records, give an analytic report on everything going on in terms of sales. With FoodCart's register/POS system, you can't only monitor your restaurant remotely, but also help you make swift decisions in order to grow a profitable restaurant business." 
    },
];

function toggleDescription(index: number) {
    activeIndex.value = activeIndex.value === index ? null : index;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("header", { class: "" }, [
      _createElementVNode("div", { class: "lg:text-[44px] text-[30px] font-semibold font-sourceSerifPro" }, "FAQs"),
      _createElementVNode("div", { class: "lg:text-sub-heading text-body-2 text-grey-200" }, "Questions? We've got answers.")
    ], -1)),
    _createElementVNode("main", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(faqs, (faq, index) => {
        return _createElementVNode("div", {
          key: index,
          class: "flex flex-col"
        }, [
          _createElementVNode("div", {
            class: "flex items-center lg:text-body-1 text-caption justify-between border-b-[1px] border-[#0000000F] py-[24px]",
            onClick: ($event: any) => (toggleDescription(index))
          }, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(faq.question), 1),
            _createVNode(_unref(Icon), {
              icon: "ant-design:caret-down-outlined",
              width: "24",
              height: "24"
            })
          ], 8, _hoisted_3),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (activeIndex.value === index)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(faq.description), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ])
      }), 64))
    ])
  ]))
}
}

})