import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px]" }
const _hoisted_2 = { class: "shadow-md rounded-[200px] bg-white relative" }
const _hoisted_3 = { class: "w-fit mx-auto pt-[32px] relative" }
const _hoisted_4 = { class: "" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppFooter from '@/components/shared/app-footer/AppFooter.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import AppNavbar from '@/components/shared/navbar/AppNavbar.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AboutUs',
  setup(__props) {

// import ActionButton from '@/components/shared/action-button/ActionButton.vue';
// import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
const imageStyle = ref({
    height: '100vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Fpremium-photo%2Fmale-cashier-smiling-supermarket-daytime_1375-21574.jpg&f=1&nofb=1&ipt=57509b37638a05cfb6c33bf6c0352faf1f17cb525152bac1111fa3df2e8e16a3&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const restaurants = ref([
    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Mart',
        location: "The FoodCart Mart is a mini website for both online(ghost) and physical restaurants to create a better ordering experience for their customers. It will help create a faster ordering process, carry out payment seamlessly, keep record and provide analytical sales report and more.",
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Register',
        location: "FoodCart register is a smart device that helps manage a restaurant with the use of adequate and concise data to scale the restaurant at zero to no cost. With our register system, profitability can be increased, record all transactions, understand your customers well and more.",
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Consultancy',
        location: "At FoodCart consult, we've been able to create and master solutions with the help of professionals that uses sophisticated tools developed by FoodCart to not only restructure but position a restaurant to be on the path to profitability.",
    },
]);

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', // Adjust the height as per your design
    width: '100%',
    borderRadius: '8px 8px 0 0'
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle(imageStyle.value),
      class: "lg:px-[60px] py-[24px] relative"
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AppNavbar)
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-sourceSerifPro lg:text-[80px] text-[45px] text-center lg:mt-[200px] mt-[60px] text-white font-bold relative" }, [
        _createElementVNode("div", { class: "px-[60px] lg:flex justify-center gap-[20px]" }, [
          _createElementVNode("div", { class: "" }, "About Us")
        ])
      ], -1)),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/16.png'),
        width: "140",
        height: "140",
        class: "absolute top-[200px] right-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/17.png'),
        width: "140",
        height: "140",
        class: "absolute bottom-[50px] left-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "lg:px-[60px] px-[20px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 lg:mt-[20px] mt-[10px] text-[#FFFFFFBF] relative" }, " We are dedicated to resolving all restaurant problems leveraging technology. ", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "lg:px-[60px] px-[20px] lg:w-[811.82px] mx-[auto] text-center lg:text-body-2 text-heading-6 lg:mt-[20px] mt-[10px] text-[#FFFFFFBF] relative" }, " We firmly understand the problems restaurants are facing. By solving functional problems inherent to every restaurant leveraging technology, we help build a smarter restaurant. With our solutions, we can help your restaurant become less costly, smarter, and more profitable using revolutionary technology. Starting with the FoodCart register system, we will help you record all your transactions and eliminate theft, gather data that will help you become more profitable and drive more sales, manage your restaurant remotely and many more. ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/registration" }, {
          default: _withCtx(() => [
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Register Now",
              class: "rounded-[99.9px]"
            })
          ]),
          _: 1
        })
      ])
    ], 4),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(AppFooter)
    ])
  ]))
}
}

})