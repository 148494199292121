import http from '../http.interceptor';

export default class ApiService {

    static async get(url: string, callback?: () => void): Promise<any> {
        const response = await http.get(url);
        callback?.()
        return response;
    }

    static async post(url: string, data: Record<string | number, any>, callback?: () => void): Promise<any> {
        const response = await http.post(url, data);
        callback?.()
        return response
    }

    static async put(url: string, data: Record<string | number, any>): Promise<any> {
        const response = await http.put(url, data);
        return response;
    }

    static async delete(url: string): Promise<any> {
        const response = await http.delete(url);
        return response;
    }
}