import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import RestaurantsView from '@/views/restaurants/RestaurantsView.vue'
import RegistrationView from '@/views/registration/RegistrationView.vue'
import BlogView from '@/views/blog/BlogView.vue'
import ProductsView from '@/views/products/ProductsView.vue'
import AboutUs from '@/views/about-us/AboutUs.vue'
import SuccessView from '@/views/success/SuccessView.vue'
import PrivacyPolicy from '@/views/privacy-policy/PrivacyPolicy.vue'
import TermsOfServices from '@/views/terms-of-services/TermsOfServices.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/restaurants',
    name: 'restaurants',
    component: RestaurantsView
  },

  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },

  {
    path: '/blog',
    name: 'blog',
    component: BlogView
  },

  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },

  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUs
  },

  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },

  {
    path: '/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicy
  },

  {
    path: '/terms-of-services',
    name: 'termsOfService',
    component: TermsOfServices
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
