<template>
    <div>
        <div :style="imageStyle" class="lg:px-[60px] py-[24px] relative">
            <div class="absolute inset-0 bg-black opacity-50"></div>

            <div class="lg:px-[60px] px-[20px]">
                <div class="shadow-md rounded-[200px] bg-white relative">
                    <AppNavbar />
                </div>
            </div>
            
            <div class="font-sourceSerifPro lg:text-[80px] text-[45px] text-center lg:mt-[100px] mt-[75px] text-white font-bold relative">
                <div class="px-[60px] lg:flex justify-center gap-[20px]">
                    <div class="">Products</div>
                    <!-- <div class="bg-white text-primary-300 lg:px-[20px] rounded-[16px] ">Stories</div> -->
                </div>
            </div>

            <ImageCard :imageUrl="require('@/assets/images/icons/16.png')" width="140" height="140" class="absolute top-[200px] right-[60px] lg:block hidden" />
            <ImageCard :imageUrl="require('@/assets/images/icons/17.png')" width="140" height="140" class="absolute bottom-[50px] left-[60px] lg:block hidden" />

            <!-- <div class="px-[60px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 lg:mt-[40px] mt-[20px] text-[#FFFFFFBF] relative">
                The good stuff. Follow our social media for the latest updates, features and even sneak peeks 👀
            </div> -->

            <!-- <div class="w-fit mx-[auto] flex items-center lg:gap-[20px] gap-[8px] mt-[32px] relative text-white">
                <a href="https://x.com/foodcartng" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:twitter-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.facebook.com/foodcart.tech" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:facebook-filled" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.youtube.com/channel/UCegkuRFbZMXD2rOJhtFg2Kg" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:youtube-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>

                <a href="https://www.instagram.com/foodcart.ng/" target="_blank" rel="noopener noreferrer" class="w-[40px] h-[40px] bg-[#1E1E1E] rounded-full relative">
                    <Icon icon="ant-design:instagram-outlined" width="20" height="20" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </a>
            </div> -->
        </div>

        <main class="lg:px-[60px] mt-[60px] mb-[-30px]">
            <!-- <div class="whitespace-nowrap overflow-auto px-[20px] no-scrollbar">
                <div class="flex lg:gap-[24px] gap-[10px] mb-[32px] border-b-[1px] w-fit font-medium text-grey-100">
                    <div class="p-[16px] border-b-[2px] border-primary-300 text-grey-300 cursor-pointer">All</div>
                    <div class="p-[16px] cursor-not-allowed">News</div>
                    <div class="p-[16px] cursor-not-allowed">Marketings</div>
                    <div class="p-[16px] cursor-not-allowed">Vendors</div>
                    <div class="p-[16px] cursor-not-allowed">Operations</div>
                </div>
            </div> -->

            <div class="grid lg:grid-cols-4 lg:gap-[32px] gap-[20px] px-[20px]">
                <div v-for="(restaurant, index) in restaurants" :key="index" class="border-[1px] border-grey-50 rounded-[8px]">
                    <div :style="getBackgroundStyle(restaurant.imageUrl)"></div>
                    <div class="px-[32px] pt-[32px] text-center">
                        <div class="font-semibold mt-[12px] text-heading-4">{{ restaurant.name }}</div>
                        <div class="my-[20px] text-grey-200">{{ restaurant.location }}</div>
                        <!-- <div class="flex items-center gap-[10px] mt-[28px]">
                            <ActionButton theme="secondary" label="Read More"/>
                        </div> -->
                    </div>
                    <div class="w-fit mx-auto pb-[32px]">
                        <router-link to="/registration">
                            <ActionButton theme="primary" label="Register Now" class="rounded-[99.9px]" />
                        </router-link>
                    </div>
                </div>
            </div>
        </main>

        <!-- <AppFaq /> -->
        
        <div class="mt-[48px]">
            <AppFooter />    
        </div>
    </div>
</template>

<script setup lang="ts">
// import ActionButton from '@/components/shared/action-button/ActionButton.vue';
// import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppFooter from '@/components/shared/app-footer/AppFooter.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import AppNavbar from '@/components/shared/navbar/AppNavbar.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';

const imageStyle = ref({
    height: '50vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Fpremium-photo%2Fblack-woman-cashier-portrait-with-cash-register-management-small-business-leadership_590464-254906.jpg&f=1&nofb=1&ipt=58eae57b2dae5a3cea1ff041d683b819274b2ebd5462703c6abc5dcacbec2440&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const restaurants = ref([
    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Mart',
        location: "The FoodCart Mart is a mini website for both online(ghost) and physical restaurants to create a better ordering experience for their customers. It will help create a faster ordering process, carry out payment seamlessly, keep record and provide analytical sales report and more.",
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Register',
        location: "FoodCart register is a smart device that helps manage a restaurant with the use of adequate and concise data to scale the restaurant at zero to no cost. With our register system, profitability can be increased, record all transactions, understand your customers well and more.",
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'FoodCart Consultancy',
        location: "At FoodCart consult, we've been able to create and master solutions with the help of professionals that uses sophisticated tools developed by FoodCart to not only restructure but position a restaurant to be on the path to profitability.",
    },
]);

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', 
    width: '100%',
    borderRadius: '8px 8px 0 0'
});

</script>

<style scoped>

</style>