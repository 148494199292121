import { computed } from 'vue';
import AuthService from '../services/auth.service';
import { useForm } from './useForm';
import * as yup from 'yup';

export const useRegistration = () => {
  // const validationSchema = yup.object().shape({
  //   name: yup.string().required('Store Name is required'),
  //   serviceOptions: yup.string().required('Store Category is required'),
  //   firstName: yup.string().required('First Name is required'),
  //   lastName: yup.string().required('Last Name is required'),
  //   email: yup.string().email('Invalid email').required('Email is required'),
  //   phone: yup.string().required('Phone Number is required'),
  //   source: yup.string().required('This field is required'),
  //   numberOfStores: yup.number().required('Number of Stores is required'),
  //   instagram: yup.string().required('Instagram Username is required'),
  //   currentPOSSolution: yup.string().required('This field is required'),
  //   notes: yup.string().required('This field is required')
  // });

  const registrationFormHandler = useForm({
    initialValues: {
      name: '',
      serviceOptions: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      source: '',
      numberOfStores: '',
      instagram: '',
      currentPOSSolution: '',
      notes: '',
      hasDevice: '',
    },
    // validationSchema,
    onSubmit: async (values: any) => {
      // Call your registration API
      console.log(values)
      try {
        await AuthService.registrationRequest(values);
        // Success action here (like routing to a success page)
      } catch (error) {
        console.error('Registration failed', error);
      }
    }
  });

  const allFieldsFilled = computed(() => {
    return Object.keys(registrationFormHandler.values).every((key) => registrationFormHandler.values[key] && !registrationFormHandler.errors[key]);
  });

  return {
    registrationFormHandler,
    allFieldsFilled
  };
};
